import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import axios from 'axios';
import { FaCircle } from "react-icons/fa";

const App = () => {
    const [commandInput, setCommandInput] = useState('');
    const [commandOutput, setCommandOutput] = useState([]);
    const [commandHistory, setCommandHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [currentDirectory, setCurrentDirectory] = useState('PS V:\\CreativeMinds\\MyCLI');
    const [pythonModeDisplay, setPythonModeDisplay] = useState('none');
    const [commandModeDisplay, setCommandModeDisplay] = useState('flex');
    const [pythonCode, setPythonCode] = useState('');
    const [pythonOutputHistory, setPythonOutputHistory] = useState([]);
    const [textareaRows, setTextareaRows] = useState(1);
    const [pyInputBoxDisplay, setPyInputBoxDisplay] = useState('flex');
    const commandInputRef = useRef(null);
    const commandPyInputRef = useRef(null);
    const endOfOutputRef = useRef(null);
    const endOfPyOutputRef = useRef(null);

    const serverUrl = 'https://python-server.creative-minds.studio';

    const projects = [
        {
            name: 'AISD',
            link: 'https://ai-smart-diagnosis-vp.streamlit.app/',
            description: 'AI Smart Diagnosis is a Streamlit web application that provides smart diagnosis for various diseases based on CT scan images.',
            repo: 'https://github.com/Vishal-Pattar/AI-Smart-Diagnosis'
        },
        {
            name: 'Konnect',
            link: 'Not Available',
            description: 'NLP based Social Media Website',
            repo: 'https://github.com/Vishal-Pattar/Konnect'
        },
        {
            name: 'Quizoo',
            link: 'https://melodious-llama-9b6b16.netlify.app',
            description: 'Quizoo is a web-based quiz application built with modern web technologies. It allows users to take quizzes on various topics and get instant feedback.',
            repo: 'https://github.com/Vishal-Pattar/Quizoo'
        },
        {
            name: 'Kuiz',
            link: 'Not Available',
            description: 'Kuiz a LAMP based Quiz app.',
            repo: 'https://github.com/Vishal-Pattar/KuizApp'
        },
        {
            name: 'VisionWizards',
            link: 'https://visionwizardswebapp.onrender.com/',
            description: 'VisionWizardsWebApp is designed to provide real-time analytics for detecting passenger falls on escalators using a dedicated CCTV camera. The system identifies fall incidents and triggers alerts to prevent accidents or train overruns.',
            repo: 'https://github.com/Vishal-Pattar/VisionWizardsWebApp'
        }
    ];

    const handleCommand = async () => {
        const trimmedCommand = commandInput.trim();
        const [cmd, ...args] = trimmedCommand.split(' ');

        const result = await executeCommand(cmd, args);

        if (cmd !== 'clear') {
            setCommandOutput(prev => [...prev, { currentDirectory, command: commandInput, result }]);
        }
        setCommandInput('');
        if (cmd !== '') {
            setCommandHistory(prev => [...prev, commandInput]);
            setHistoryIndex(commandHistory.length + 1);
        }
    };

    const executeCommand = async (cmd, args) => {
        let result;

        switch (cmd) {
            case 'whoiam':
                result = 'Vishal Pattar';
                break;
            case 'cd':
                result = handleCdCommand(args);
                break;
            case 'domain':
                window.open('https://www.creative-minds.studio', '_blank');
                result = 'Opening website...';
                break;
            case 'github':
                window.open('https://github.com/Vishal-Pattar', '_blank');
                result = 'Opening Github...';
                break;
            case 'portfolio':
                window.open('https://vishal-pattar.github.io', '_blank');
                result = 'Opening Portfolio...';
                break;
            case 'resume':
                window.open('https://drive.google.com/file/d/1Zh3Jp7sEcutdjXNskgbdj0_5uocf31SH/view', '_blank');
                result = 'Opening Resume...';
                break;
            case 'python':
                setCommandOutput(prev => [...prev, { currentDirectory, command: cmd, result: 'Python server is initializing...' }]);
                result = await initializePythonServer();
                setCommandOutput(prev => {
                    const newOutput = [...prev.slice(0, -1)];
                    return newOutput;
                });
                break;
            case 'echo':
                result = args.join(' ');
                break;
            case 'help':
                result = 'Available commands: whoiam, projects, domain, github, clear, echo, help';
                break;
            case '':
                result = '';
                break;
            case 'clear':
                setCommandOutput([]);
                result = '';
                break;
            default:
                result = handleProjectCommands(cmd, args);
        }

        return result;
    };

    const handleCdCommand = (args) => {
        if (args.length === 1) {
            const directoryName = args[0];
            if (/^[0-9a-zA-Z]+$/.test(directoryName)) {
                setCurrentDirectory(currentDirectory + '\\' + directoryName);
                return `Changed to ${directoryName}`;
            } else if (directoryName === '..') {
                const parts = currentDirectory.split('\\');
                if (parts.length > 3) {
                    parts.pop();
                    setCurrentDirectory(parts.join('\\'));
                } else {
                    return 'Not Allowed';
                }
            } else {
                return 'Usage: cd [<directory name>|..]';
            }
        } else {
            return 'Usage: cd [<directory name>|..]';
        }
    };

    const handleProjectCommands = (cmd, args) => {
        if (currentDirectory === 'PS V:\\CreativeMinds\\MyCLI\\Projects') {
            const project = projects.find(p => p.name.toLowerCase() === cmd.toLowerCase());
            if (project) {
                const option = args[0];
                switch (option) {
                    case '--live':
                        window.open(project.link, '_blank');
                        return `Opening project '${project.name}' in a new tab: <a href="${project.link}" target="_blank" style="text-decoration: underline; color:white;">${project.link}</a>`;
                    case '--description':
                        return `Description of '${project.name}': ${project.description}`;
                    case '--repo':
                        window.open(project.repo, '_blank');
                        return `GitHub repository for '${project.name}': <a href="${project.repo}" target="_blank" style="text-decoration: underline; color:white;">${project.repo}</a>`;
                    default:
                        return 'Usage: <project name> [--live|--description|--repo]';
                }
            } else if (cmd === 'ls') {
                return projects.map(p => p.name).join('\n');
            }
        } else {
            return 'Command not recognized';
        }
    };

    const handleCommandInput = (event) => {
        if (event.key === 'Enter') {
            handleCommand();
        } else if (event.key === 'ArrowUp') {
            if (historyIndex > 0) {
                setHistoryIndex(prev => prev - 1);
                setCommandInput(commandHistory[historyIndex - 1]);
            }
        } else if (event.key === 'ArrowDown') {
            if (historyIndex < commandHistory.length - 1) {
                setHistoryIndex(prev => prev + 1);
                setCommandInput(commandHistory[historyIndex + 1]);
            } else {
                setHistoryIndex(commandHistory.length);
                setCommandInput('');
            }
        }
    };

    const executePythonCode = async () => {
        let pythonOutput, pythonError;
        setPyInputBoxDisplay('none');
        setPythonOutputHistory(hist => [...hist, { Input: pythonCode.replace(/\n/g, '\n>>> '), Output: '', Error: '' }]);
        try {
            const response = await axios.post(serverUrl + '/run_code', { code: pythonCode });
            pythonOutput = response.data.stdout;
            pythonError = response.data.error || response.data.stderr;
        } catch (error) {
            pythonError = 'An error occurred while running the code.\n' + error;
        }
        setPythonOutputHistory(hist => {
            const newOutput = [...hist.slice(0, -1)];
            return newOutput;
        });
        setPythonOutputHistory(hist => [...hist, { Input: pythonCode.replace(/\n/g, '\n>>> '), Output: pythonOutput, Error: pythonError }]);
        setPyInputBoxDisplay('flex');
    };

    const initializePythonServer = async () => {
        let result;
        try {
            setCommandModeDisplay('none');
            await axios.get(serverUrl + '/hello');
            result = 'Python 3.11.5 on Creative Minds';
            setPythonModeDisplay('block');
        } catch (error) {
            setCommandModeDisplay('flex');
            result = 'Python server is initializing...\nAn error occurred while Initializing Python Server.';
        }
        return result;
    };

    const handlePythonInput = (event) => {
        setTextareaRows(pythonCode.split('\n').length);
        if (event.key === 'Enter' && !event.shiftKey) {
            if (pythonCode === 'clear') {
                setPythonCode('');
                setPythonOutputHistory([]);
                event.preventDefault();
            } else if (pythonCode !== 'exit()') {
                executePythonCode();
                setPythonCode('');
                event.preventDefault();
            } else {
                setPythonCode('');
                setCommandOutput(prev => {
                    const combinedPythonOutput = pythonOutputHistory.map(item => {
                        const input = `<span class='pyboxInput'><span>>>> </span>${item.Input}</span>`;
                        const output = item.Output ? `<span class='pyboxOutput'>${item.Output}</span>` : '';
                        const error = item.Error ? `<span class='pyboxError'>${item.Error}</span>` : '';
                        return `${input}\n${output}${error}`;
                    }).join('');

                    const newOutput = [...prev];
                    newOutput[newOutput.length - 1].result += `\n<div style='margin-top: 10px;'>${combinedPythonOutput}</div>`;

                    return newOutput;
                });

                setPythonOutputHistory([]);
                setPythonModeDisplay('none');
                setCommandModeDisplay('flex');
            }
            setTextareaRows(1);
        } else if (event.key === 'Delete') {
            event.preventDefault();
        } else if (event.shiftKey && event.key === 'Enter') {
            setTextareaRows(textareaRows + 1);
        }
    };

    useEffect(() => {
        if (commandInputRef.current) {
            commandInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (commandPyInputRef.current) {
            commandPyInputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (endOfOutputRef.current) {
            endOfOutputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [commandOutput]);

    useEffect(() => {
        if (endOfPyOutputRef.current) {
            endOfPyOutputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [pythonOutputHistory]);

    return (
        <div className="App">
            <div className='Topbar'>
                <span>
                    <i><FaCircle style={{ color: '#EE4B2B' }} /></i>
                    <i><FaCircle style={{ color: '#66ff00' }} /></i>
                    <i><FaCircle style={{ color: '#0096FF' }} /></i>
                </span>
            </div>
            <div className="terminal">
                <div className="output">
                    {commandOutput.map((line, index) => (
                        <div key={index}>
                            <span className='prefix-command'>
                                <span className="prefix">
                                    <span className='pr-svg pr-svg-l'>
                                        <span className='pr-svg-1'>.</span>
                                        <span className='pr-svg-2'>.</span>
                                    </span>
                                    <span className='pr-svg-c'>{line.currentDirectory + ">"}</span>
                                    <span className='pr-svg pr-svg-r'>
                                        <span className='pr-svg-1'>.</span>
                                        <span className='pr-svg-2'>.</span>
                                    </span>
                                </span>
                                <span className="command">{line.command}</span>
                            </span>
                            <span className="result" dangerouslySetInnerHTML={{ __html: line.result }} />
                        </div>
                    ))}
                    <div ref={endOfOutputRef} />
                </div>
                <div className='pybox' style={{ 'display': pythonModeDisplay }}>
                    {pythonOutputHistory.map((line, index) => (
                        <div key={index}>
                            <div className='pyboxInput'>
                                <span>{'>>> '}</span>{line.Input}</div>
                            <div className='pyboxOutput'>{line.Output}</div>
                            <div className='pyboxError'>{line.Error}</div>
                        </div>
                    ))}
                    <div ref={endOfPyOutputRef} />
                    <div className='textarea-box' style={{ 'display': pyInputBoxDisplay }} >
                        {'>>>' + '\n>>>'.repeat(textareaRows - 1)}
                        <textarea
                            ref={commandPyInputRef}
                            rows={textareaRows}
                            cols="115"
                            value={pythonCode}
                            onChange={(e) => setPythonCode(e.target.value)}
                            placeholder="Enter your Python code here"
                            onKeyDown={handlePythonInput}
                            style={{ resize: 'none' }}
                        />
                    </div>
                </div>
                <div className='inputbox' style={{ 'display': commandModeDisplay }}>
                    <div className='prefix'>
                        <span className='pr-svg pr-svg-l'>
                            <span className='pr-svg-1'>.</span>
                            <span className='pr-svg-2'>.</span>
                        </span>
                        <span className='pr-svg-c'>{currentDirectory + ">"}</span>
                        <span className='pr-svg pr-svg-r'>
                            <span className='pr-svg-1'>.</span>
                            <span className='pr-svg-2'>.</span>
                        </span>
                    </div>

                    <input
                        ref={commandInputRef}
                        type="text"
                        value={commandInput}
                        onChange={(e) => setCommandInput(e.target.value)}
                        onKeyDown={handleCommandInput}
                        className="input"
                        autoFocus
                    />
                </div>
            </div>
        </div>
    );
};

export default App;